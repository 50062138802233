import createTicket from "./components/create-ticket/index.vue"
import {mapActions, mapGetters} from "vuex";
import ticket from './components/ticket/index.vue'
import chatForm from './components/chat-form/index.vue'
export default {
  name: "messages",
  data() {
    return {
      addNewTicket: false,
      ticketData:null,
    }
  },
  computed: {
    ...mapGetters({
      tickets: `auth/tickets`
    })
  },
  components: {
    createTicket,
    ticket,
    chatForm
  },
  created() {
    this.fetchTickets()
    this.$emit('tickets', this.tickets)
  },
  watch:{
    'tickets'(newVal){
      if(newVal){
        // this.ticketData = newVal[this.ticketId]
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTickets: `auth/GET_TICKETS`
    }),
    close(value) {
      this.addNewTicket = value
    },
    showDialog(value){
      this.ticketData = null
      this.ticketData = value
      this.addNewTicket = false
    },
    change(){
      this.addNewTicket = !this.addNewTicket
      this.ticketData = false
    }
  }
}