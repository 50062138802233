import {mapActions} from "vuex";

export default {
  props:['ticket'],
  name: "ticket",
  methods:{
    ...mapActions({
      openTicket: `auth/OPEN_TICKET`
    }),
    showDialog(ticket){
      this.$emit('showDialog',this.ticket);
      this.openTicket(this.ticket)
      this.ticket.unread = 0;
    }
  }
}