import {mapMutations, mapGetters, mapActions} from 'vuex';
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "create-ticket",
  mixin: [validationMixin],
  data() {
    return {
      validationErrors: {},
      payload: {
        title: '',
        message: ''
      }
    }
  },
  validations() {
    return {
      payload: {
        title: {
          required,
          maxLength: maxLength(50)
        },
        message: {
          required,
          maxLength: maxLength(150)
        },
      },
    }
  },
  computed: {
    titleErrors() {
      let error = [];
      if (!this.$v.payload.title.$dirty) {
        return error;
      }
      if (!this.$v.payload.title.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.title.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50))
      }
      return error;
    },
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.title.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 150))
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      createTicket: `auth/CREATE_TICKET`,
      fetchTickets: `auth/GET_TICKETS`
    }),
    close(){
      this.$emit('clicked', false)
    },
    submit() {
      let obj = {}
      this.$v.$touch();
      if (!this.$v.$invalid) {
        obj.topic = this.payload.title
        obj.text = this.payload.message
        this.createTicket(obj).then(() => {
          this.$toasted.success(this.$t('ticketCreate'));
          this.payload.title = ''
          this.payload.message = ''
          this.$emit('clicked', false)
          this.fetchTickets()
        }).catch(error => {
          if (error.response.status === 402) {
            console.log(error.response)
          }
        })
      }
    }
  }
}