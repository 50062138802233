import {mapActions, mapGetters} from "vuex";

export default {
  name: "chat",
  props: ['ticketData'],
  data(){
    return{
      payload:{
        text:{
          text:''
        }
      },
    }
  },
  computed:{
    ...mapGetters({
      tickets:`auth/tickets`
    })
  },
  watch:{
    'ticketData'(newVal){
      if(newVal){
        return newVal
      }
    },
    tickets: {
      handler: function(val) {
        console.log(val)
        let newTicketData = val.find(ticket => {
          return ticket.id === this.ticketData.id
        })
        if(newTicketData){
          this.ticketData.messages = newTicketData.messages
        }
      },
      deep: true
    }
  },
  created() {
  },
  mounted() {
    this.scrollToEnd()
  },
  methods: {
    ...mapActions({
      send:`auth/SEND_MESSAGE`,
      fetchTickets: `auth/GET_TICKETS`
    }),
    scrollToEnd() {
      const el = this.$el.querySelector("#container");
      if (el) {
        el.scrollTop = el.scrollHeight;
      }
    },
    sendMessage(){
      let obj = {}
      obj.id = this.ticketData.id
      obj.text = this.payload.text
      this.send(obj).then(()=>{
        this.fetchTickets().then(()=> {
          this.payload.text.text = ''
          this.$toasted.success(this.$t('messageSend'));
          this.scrollToEnd()
        })
       
        
      })

    },
    emptyMessageToast () {
      this.$toasted.error(this.$t('emptyMessage'));
    }
  }
}